/*#region ImportFonts */
@font-face {
  font-family: "evaFont";
  src: url("./assets/fonts/Eva-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "evaFontBold";
  src: url("./assets/fonts/Eva-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "robotoFontMedium";
  src: url("./assets/fonts/Roboto-Light.ttf") format("truetype");
}

/*#endregion*/

/*#region Set Fonts */
.airfox-font-bold {
  font-family: "evaFontBold";
}

.airfox-font-normal {
  font-family: "evaFont";
}

.font-color-grey {
  color: #444444;
}

.font-roboto {
  font-family: 'robotoFontMedium', sans-serif;
}

/*#endregion*/

/*#region Keyframes */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/*#endregion*/

/*#region All Screens */
.body {
  min-height: 100vh;
  position: relative;
  overflow: auto;
  font-family: 'robotoFontMedium', sans-serif;
  background-color: #f0f0f0;
}

.container-content {
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
}

.container-custom {
  position: relative;
  margin-top: -100px;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  background-color: #253F58;
}

.custom-h1 {
  color: white;
  font-family: "evaFontBold";
}

.custom-h1-blue {
  color: #253F58;
  font-family: "evaFontBold";
}

.card {
  background-color: #1E2A38;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: #f0f0f0;
  margin-top: 30px;
  font-family: 'Roboto-Medium', sans-serif;
  display: flex;
  flex-direction: column;
}

.card-body {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.title-with-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-right {
  margin-left: auto;
}

.fade-in-delayed {
  opacity: 0;
  /* Initial opacity */
  animation: fadeIn 5s ease-in-out forwards;
  /* 5s duration */
  animation-iteration-count: 1;
  /* Ensure animation plays only once */
}

.join-button {
  width: 100%;
  margin-top: auto;
  background-color: #253F58;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  font-family: "evaFontBold";
  cursor: pointer;
}

.join-button:hover {
  width: 100%;
  margin-top: auto;
  background-color: #FEC748;
  color: #253F58;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  font-family: "evaFontBold";
  cursor: pointer;
}

.custom-hr {
  height: 2px;
  background-color: #253F58;
}

.patreon-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #f96854;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}
.patreon-button:hover {
  background-color: #e55a4a;
}
.patreon-button img {
  margin-right: 10px;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  background-color: #FEC748;
}

.nav-pills {
  display: flex;
  justify-content: space-between;
}

.nav-item {
  flex: 1;
}

.nav-link {
  text-align: center;
  width: 100%;
  color: white;
}
/*#endregion*/

/*#region Big Screen */
@media (min-width: 1024px) {
  .hero-image-container {
    position: relative;
    width: 100%;
    height: 1300px;
    overflow: hidden;
  }

  .hero-image {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .hero-image-head {
    position: absolute;
    top: 30%;
    left: 70%;
    transform: translate(-50%, -50%);
    width: 1800px;
    opacity: 0;
    animation: fadeIn 5s ease-in-out forwards;
    animation-iteration-count: 1;
  }
}

/*#endregion*/

/*#region Small Screen */
@media (max-width: 1023px) {
  .hero-image-container {
    position: relative;
    width: 100%;
    height: 380px;
    overflow: hidden;
  }

  .hero-image {
    position: relative;
    width: 100%;
    object-fit: cover;
  }

  .hero-image-head {
    position: absolute;
    top: 20%;
    left: 70%;
    transform: translate(-50%, -50%);
    width: 500px;
    opacity: 0;
    animation: fadeIn 5s ease-in-out forwards;
    animation-iteration-count: 1;
  }
}

/*#endregion*/